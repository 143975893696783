import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Textarea from "../components/Textarea";
import Input from "../components/Input";
import FormHint from "../components/FormHint";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import FormError from "../components/FormError";
import PageWrapper from "../components/PageWrapper";
import { FUN_FACTS } from "../flags";
import RichTextEditor, {
  htmlFromValue,
  valueFromHTML,
} from "../components/RichTextEditor";
import runningInBrowser from "../util/runningInBrowser";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_QUESTION_TEMPLATE = gql`
  query GetQuestionTemplate($roundTemplateId: ID!, $questionNumber: Int!) {
    questionTemplate: getQuestionTemplate(
      roundTemplateId: $roundTemplateId
      questionNumber: $questionNumber
    ) {
      id
      number
      text
      answer
      alternateAnswers
      facts
    }
  }
`;

const SAVE_QUESTION_TEMPLATE = gql`
  mutation SaveQuestionTemplate(
    $roundTemplateId: ID!
    $text: String!
    $answer: String!
    $alternateAnswers: String
    $facts: String
    $questionNumber: Int
  ) {
    saveQuestionTemplate(
      roundTemplateId: $roundTemplateId
      text: $text
      answer: $answer
      alternateAnswers: $alternateAnswers
      facts: $facts
      questionNumber: $questionNumber
    ) {
      questionTemplate {
        id
      }
    }
  }
`;

const DELETE_QUESTION_TEMPLATE = gql`
  mutation DeleteQuestionTemplate(
    $roundTemplateId: ID!
    $questionNumber: Int!
  ) {
    deleteQuestionTemplate(
      roundTemplateId: $roundTemplateId
      questionNumber: $questionNumber
    ) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WideFormField = styled(FormField)`
  width: 600px;
`;

const toolbarConfig = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const QuestionTemplateEditor = ({
  roundTemplateId,
  questionNumber,
  originalValues,
  onDelete,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
    isSubmitting,
  } = useFormikContext();

  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () =>
    values.text !== originalValues.text ||
    values.answer !== originalValues.answer ||
    values.alternateAnswers !== originalValues.alternateAnswers ||
    values.facts !== originalValues.facts ||
    values.source !== originalValues.source;

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>
        {/*{roundNumber === "new" ? "Add a Round" : "Edit Round"}*/}
        Edit Question
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          <WideFormField>
            <Label htmlFor="text">Text</Label>
            <Textarea
              type="text"
              name="text"
              autoComplete="off"
              value={values.text}
              onChange={handleChange}
            />
            {errors.text && touched.text && (
              <FormError>{errors.text}</FormError>
            )}
          </WideFormField>

          <WideFormField>
            <Label htmlFor="answer">Answer</Label>
            <Input
              type="text"
              name="answer"
              autoComplete="off"
              value={values.answer}
              onChange={handleChange}
            />
            {errors.answer && touched.answer && (
              <FormError>{errors.answer}</FormError>
            )}
          </WideFormField>

          <WideFormField>
            <Label htmlFor="alternateAnswers">Alternate Answers</Label>
            <Textarea
              type="text"
              name="alternateAnswers"
              autoComplete="off"
              value={values.alternateAnswers}
              onChange={handleChange}
            />
            {errors.alternateAnswers && touched.alternateAnswers && (
              <FormError>{errors.alternateAnswers}</FormError>
            )}
            <FormHint>One alternate answer per line</FormHint>
          </WideFormField>

          {FUN_FACTS && (
            <>
              {/*<WideFormField>*/}
              {/*  <Label htmlFor="source">Source</Label>*/}
              {/*  <Input*/}
              {/*    type="text"*/}
              {/*    name="source"*/}
              {/*    autoComplete="off"*/}
              {/*    disabled={isStarted}*/}
              {/*    value={values.source}*/}
              {/*    onChange={handleChange}*/}
              {/*  />*/}
              {/*  {errors.source && touched.source && (*/}
              {/*    <FormError>{errors.source}</FormError>*/}
              {/*  )}*/}
              {/*</WideFormField>*/}

              <WideFormField>
                <Label htmlFor="facts">Facts</Label>
                <RichTextEditor
                  className="text-editor"
                  toolbarConfig={toolbarConfig}
                  value={values.facts}
                  onChange={(value) => setFieldValue("facts", value)}
                />
                {errors.facts && touched.facts && (
                  <FormError>{errors.facts}</FormError>
                )}
                <FormHint>
                  Try to keep it short (i.e. 5 lines or fewer)
                </FormHint>
              </WideFormField>
            </>
          )}
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save Question"}
        </Button>
        <Link to={`/create-edit-round-template?id=${roundTemplateId}`}>
          <Button size="large">Close</Button>
        </Link>
        <DangerButton
          size="large"
          disabled={!questionNumber}
          onClick={handleDeleteClick}
        >
          Delete Question
        </DangerButton>
      </ActionBar>
      {showDeleteModal && (
        <Modal
          title="Delete Question"
          text="Delete this question from the round?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
};

export default function CreateEditQuestion({ location }) {
  const { roundTemplateId, questionNumber } = queryString.parse(
    location.search
  );

  const { data } = useQuery(GET_QUESTION_TEMPLATE, {
    variables: {
      roundTemplateId,
      questionNumber,
    },
    skip: !questionNumber,
  });

  const [saveQuestionTemplate] = useMutation(SAVE_QUESTION_TEMPLATE);
  const [deleteQuestionTemplate] = useMutation(DELETE_QUESTION_TEMPLATE);

  if (questionNumber && !data) {
    return null;
  }

  const originalValues = (data && data.questionTemplate) || {};

  let facts = null;
  if (runningInBrowser()) {
    facts = valueFromHTML(
      (data && data.questionTemplate && data.questionTemplate.facts) || ""
    );
  }

  const initialValues = {
    text: (data && data.questionTemplate && data.questionTemplate.text) || "",
    answer:
      (data && data.questionTemplate && data.questionTemplate.answer) || "",
    alternateAnswers:
      (data &&
        data.questionTemplate &&
        data.questionTemplate.alternateAnswers) ||
      "",
    facts: facts,
    source:
      (data && data.questionTemplate && data.questionTemplate.source) || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.text) {
      errors.text = "You must enter text for the question";
    }
    if (!values.answer) {
      errors.answer = "You must enter an answer to the question";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    let facts = htmlFromValue(values.facts);

    if (facts === "<p><br></p>") {
      facts = "";
    }

    await saveQuestionTemplate({
      variables: {
        roundTemplateId,
        questionNumber,
        text: values.text,
        answer: values.answer,
        alternateAnswers: values.alternateAnswers,
        facts,
      },
    });
    await navigate(`/create-edit-round-template?id=${roundTemplateId}`);
  };

  const handleDelete = async () => {
    await deleteQuestionTemplate({
      variables: {
        roundTemplateId,
        questionNumber: questionNumber,
      },
    });
    await navigate(`/create-edit-round-template?id=${roundTemplateId}`);
  };

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <QuestionTemplateEditor
          roundTemplateId={roundTemplateId}
          questionNumber={questionNumber}
          originalValues={originalValues}
          onDelete={handleDelete}
        />
      </Formik>
    </PageWrapper>
  );
}
